.header__actions {
  .searchform {
    display: flex;
    align-items: center;
    position: relative;

    label {
      display: none;
    }

    input[type="text"] {
      font-family: "Muli", Arial, sans-serif;
      font-size: 1.125rem;
      border: 1px solid #EDEEF0;
      color: $dark-gray;
      outline: none;
      padding: 14px;
      -webkit-appearance: none;
      border-radius: 0;
      box-sizing: border-box;
      width: 100%;
      padding-right: 3.125rem;

      &::placeholder {
        color: $dark-gray;
      }
    }

    .form-actions {
      position: absolute;
    }

    &__close {
      cursor: pointer;
      margin-right: 20px;
    }

    &--trigger {
      position: relative;
      cursor: pointer;

      .fa-search {
        width: rem(24);
        height: rem(24);
        opacity: 1;
        transition: opacity 0s ease .25s;
      }

      &.is-expanded {

        .fa-search {
          opacity: 0;
          transition: opacity 0s ease 0s;
        }

        .search-input__icon--x {
          opacity: 1;
          transition: opacity 0s ease .05s;
        }
      }
    }

    &--md {
      display: none;
      transform: translateX(0);

      @media only screen and (min-width: $medium2) {
        display: block;
        width: 0;
        right: -42px;
        overflow: hidden;
        transition: width .5s ease, transform .3s ease;
        transform-origin: 50% 0;
        margin-right: rem(18);
        z-index: 1;

        form {
          position: relative;
        }

        &.is-expanded {
          transform: translateX(2.375rem);
          right: 0;
          width: rem(220);

          @media only screen and (min-width: $large) {
            width: rem(267);
          }
        }
      }

      .form-actions {
        position: absolute;
        right: 17px;
        top: 13px;
      }

      input[type="text"] {
        width: 100%;
        padding-left: rem(16);
        padding-bottom: rem(12);
      }

      button[type="submit"] {
        @include visually-hidden;
      }

      .search-toggle {
        position: relative;
        float: left;
        margin: 8px rem(6) 0 0;
    
        &__bar {
          display: block;
          width: rem(30);
          height: rem(30);
          top: 12px;
          //position: absolute;
    
          &::before,
          &::after {
            content: '';
            position: absolute;
            display: block;
            left: 0;
            width: rem(30);
            height: 0.1875rem;
            background: $dark-gray;
            transition: background 0.2s;
          }

          &::before {
            top:rem(15);
            transform: rotate(45deg);
          }
  
          &::after {
            top:rem(15);
            transform: rotate(-45deg);
          }
        }
      }

      &:not(.is-expanded) {
        .search-toggle {
          &__bar {
            &::before,
            &::after {
              background: transparent;
              transition: background 0.2s;
            }
          }
        }
      }
    }
  }
}

.header__menu {
  .component-form-search {
    padding: 0 0 20px 0;
    width: 100%;
    order: 1;

    @media only screen and (min-width: $small) {
      padding: 0 60px;
      margin-bottom: 60px;
      order: 3;
    }

    .searchform {
      @include search-form;

      input[type="text"] {
        border: 15px solid $gray;
        height: 90px;
      }

      button[type="submit"] {
        background-color: transparent;
        right: 15px;
        top: 15px;

        i {
          color: $blue;
        }
      }
    }
  }
}
