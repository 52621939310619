.header {
  &__bottom {
    &__boundary {
      #header-full-menu{
        @media only screen and (min-width: $medium2) {
          display: none;
        }
      }
    }

    .menu--level-0 {
      &.menu {
        display: flex;
        margin: 0;
        padding: 0;
        justify-content: center;

        @media only screen and (max-width: $medium2) {
          display: none;
        }
      }

      >.menu {

        &__items {
          display: flex;
          height: 100%;
          align-items: center;

          >.menu__item {
            display: flex;
            align-items: center;
            height: 100%;

            &:hover {

              >a {
                color: $blue;
                z-index: 200;
              }

              .menu--level-1 {
                pointer-events: all;
                opacity: 1;
                z-index: 100;
                visibility: visible;
                max-width: rem(260);
              }
            }

            >a {
              font-size: rem(18);
              padding: 20px 30px;
              color: $dark-gray;
              text-decoration: none;
            }
          }
        }
      }
    }

    .menu--level-1 {
      position: absolute;
      pointer-events: none;
      bottom: 0;
      padding-top: rem(16);
      padding-bottom: rem(16);
      background-color: $white;
      transform: translateY(100%);
      z-index: -1;
      visibility: hidden;
      display: flex;

      .menu {

        &__boundary {
          display: flex;
          max-width: $container-width;
          width: 100%;
          margin: 0 auto;
        }

        &__items {

          &--columns {
            column-count: 2;
            column-width: 50%;
          }

          .menu__item {
            padding: rem(8) 0;
            list-style: none;
            transition: background-color $fast-duration ease;

            &:hover {
              background-color: $blue;

              a {
                color: $white;
              }
            }

            a {
              display: block;
              color: $dark-gray;
              text-decoration: none;
              font-size: rem(14);
              line-height: rem(20);
              font-weight: $bold;
              padding-right: rem(40);
              padding-left: rem(40);
              padding-top: rem(6);
              padding-bottom: rem(6);
              transition: color $fast-duration ease;
            }
          }
        }
      }
    }
  }

  &__primary-navigation {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    transition: opacity 0.15s ease;

    &__description {
      color: $blue;
      font-size: rem(16);
      line-height: rem(24);
      width: 340px;
    }

    &__dash {
      width: 45px;
      height: 5px;
      background-color: $blue;
      margin: 5px $desktop-vertical-padding;
    }
  }
}
