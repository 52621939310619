.mainHeader {
  .menu-toggle {
    position: relative;
    //top: 1px;
    //display: flex;
    align-content: center;

    @media only screen and (min-width: $medium2) {
      left: 0;
    }

    &__bar {
      display: block;
      background: $dark-gray;
      width: rem(30);
      height: 0.1875rem;
      margin: 0.84375rem 0;
      top: 12px;
      //position: absolute;
      transition: background 0s .2s;

      &::before,
      &::after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        width: rem(30);
        height: 0.1875rem;
        background: $dark-gray;
      }

      &::before {
        top: calc(0.84375rem - 10px);
        transition: top .2s ease .2s, transform .2s ease 0s;
      }

      &::after {
        bottom: calc(0.84375rem - 10px);
        transition: bottom .2s ease .2s, transform .2s ease 0s;
      }
    }

    &.is-active {
      span {
        background: none;

        &::before {
          top: 0.84375rem;
          transform: rotate(45deg);
          transition: top .2s ease 0s, transform .2s ease .2s;
        }

        &::after {
          bottom: 0.84375rem;
          transform: rotate(-45deg);
          transition: bottom .2s ease 0s, transform .2s ease .2s;
        }
      }
    }
  }

  .menu__panel {
    //@include standard-shadow;

    opacity: 0;
    transition: transform 350ms ease, opacity 350ms ease;
    transform: translateX(20px);
    pointer-events: none;
  }

  .menu__panel--active {
    transform: translateX(0);
    opacity: 1;
    z-index: 10;
    pointer-events: auto;
  }

  .menu__panel--active-trail {
    transform: translateX(0);
    z-index: 1;
  }

  .menu__panel--child-open {
    opacity: 1;
    overflow: hidden;
  }

  .header__menu--main__stage {
    position: relative;
    height: 100%;
  }

  .header__menu {
    position: fixed;
    right: 0;
    bottom: 0;
    top: rem(109);
    margin: 0;
    width: 100%;
    background: $gray;
    transition: top .3s ease, transform .3s ease, opacity .3s ease;
    opacity: 0;
    transform: translate(20px, 0);
    pointer-events: none;
    z-index: 200;

    @media only screen and (min-width: $small) {
      width: 578px;
    }

    @media only screen and (min-width: $medium2) {
      top: rem(130);
    }

    @media only screen and (min-width: $large) {
      top: rem(130);
    }

    .header--compact & {
      @media only screen and (min-width: $medium2) {
        top: $header-compact-height;
      }
    }

    li {
      list-style: none;
    }

    .menu__panel--active {
      pointer-events: none;
    }

    .header--show-menu & {
      opacity: 1;
      transform: translate(0, 0);
      pointer-events: auto;

      .menu__panel--active {
        pointer-events: auto;
      }
    }

    nav {
      height: 100% !important; // override height set by module
    }
  }

  .menu__panel {
    background: $white;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 100% !important; // override height set by module

    .menu-container {
      padding: 0 20px 35px 20px;
      margin: 0;
      width: 100%;

      @media only screen and (min-width: $small) {
        padding: 0 60px 0 60px;
      }
    }

    &__primary {
      &.menu-container {
        margin-bottom: 20px;

        @media only screen and (min-width: $small) {
          margin-bottom: 60px;
        }

        .back {
          > a {
            color: $blue;
          }
        }
      }
    }

    .menu__search {
      width: 100%;
      position: relative;

      .form-search {
        width: 100%;
        padding: 0 20px 35px 20px;
        position: absolute;

        @media only screen and (min-width: $small) {
          padding: 0 60px 0 60px;
        }

        input[type="text"] {
          font-family: "Muli", Arial, sans-serif;
          font-size: rem(16);
          line-height: 1;
          border: 1px solid #EDEEF0;
          color: $dark-gray;
          outline: none;
          padding: 15px;
          -webkit-appearance: none;
          border-radius: 0;
          box-sizing: border-box;
          width: 100%;
          padding-right: 3.125rem;

          &::placeholder {
            color: $dark-gray;
          }
        }

        button[type="submit"] {
          @include visually-hidden;
        }
      }

      i.menu__search__fa-search {
        width: 100%;
        height: calc(30px + 1.25rem);
        padding: 13px 20px 0 20px;
        text-align: right;
        font-size: 1.25rem;
        position: absolute;
        -webkit-pointer-events: none;
        -moz-pointer-events: none;
        pointer-events: none;

        @media only screen and (min-width: $small) {
          padding: 13px 60px 0 60px;
        }

        &::before {
          margin-right: 0.9375rem;
        }
      }
    }
  }
  

  .menu-item {
    &:first-child {
      border-top: none;
    }

    &__link {
      border-bottom: 1px solid $gray;
      padding: rem(7) rem(9) rem(7) rem(18);

      &:first-of-type{
        border-top: 1px solid $gray;
      }

      .link {
        display: flex;
        align-items: center;

        .menu-item {
          &:first-child {
            font-size: rem(16);
            line-height: 1;
            flex: 1;
            color: $dark-gray;
            text-decoration: none;
            display: block;
            padding: 15px 15px 13px 0;

            @media only screen and (min-width: $small) {
              padding: 13px 15px 11px 0;
              font-size: rem(16);
            }
          }

          &__explore {
            padding: rem(7) rem(9);
          }

          &.current-menu-item {
            font-weight: 700;
            color: $blue;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    
  }

  .menu-item__back {
    .back,
    .back-to-top {

      a {
        font-size: rem(24);
        line-height: 1;
        flex: 1;
        text-decoration: none;
        display: block;
        padding: 13px 0 11px;
        color: $gray;

        &::before {
          @include fa-icon;
          content: $fa-var-chevron-left;
          margin: 0 10px 0 0;
          font-size: rem(16);
          vertical-align: middle;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .back-to-top {

      a::before {
        content: $fa-var-chevron-up;
        position: relative;
        top: -3px;
      }
    }

    &.menu-item__level1 {
      .back-to-top {
        border-bottom: none;
      }
    }
  }

  .menu-item__back {
    &+.menu-item__link {
      border-top: 1px solid $gray;
    }
  }

  .menu__panel__title__item {
    .menu__panel__title__link {
      border-top: 1px solid $gray;
      font-size: rem(20);
      line-height: 1;
      font-weight: 700;
      flex: 1;
      text-decoration: none;
      display: block;
      padding: 13px 0 11px 0;
      color: $gray;

      @media only screen and (min-width: $small) {
        font-size: rem(24);
      }

      &:empty {
        display: none;
      }
    }
  }

  .menu__social {
    padding: 0 60px 0 60px;
    width: 100%;
    margin-bottom: 60px;

    &-links {
      list-style: none;
      margin: 0;
      display: flex;

      &__link {
        a {
          font-size: rem(25);
          color: $dark-gray;
          margin: 0 rem(15);
          transition: color $fast-duration ease;
        }
      }
    }

    @media only screen and (max-width: $small) {
      padding: 0 20px 35px 20px;
      margin-bottom: 20px;
    }
  }

  .menu__secondary {
    display: flex;
    position: relative;
    padding: 0 20px;
    width: 100%;

    @media only screen and (min-width: $small) {
      padding: 0 60px;
    }

    .menu__panel & {
      margin: -20px 0 $desktop-vertical-padding 0;
    }

    &-list {
      width: 100%;
      margin: 0;
      padding: 0;

      &:first-child {
        margin: 0;
      }
    }

    &-item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &:last-child {
        border-bottom: none;
      }

      a {
        display: block;
        padding: 14px 0 10px 0;
        font-size: rem(16);
        color: $blue;
        text-decoration: none;

        @media only screen and (min-width: $small) {
          font-size: rem(18);
        }

        &:hover {
          color: $gray;
        }
      }
    }
  }
}

// fade out content
.header {

    &__overlay {
      content: '';
      position: fixed;
      z-index: 100;
      left: 0;
      top: rem(109);
      right: 0;
      height: 100%;
      background: black;
      transition: top .2s ease, opacity .2s ease;
      opacity: 0;
      pointer-events: none;

      @media only screen and (min-width: $medium2) {
        top: rem(130);
        display: none;
      }

      @media only screen and (min-width: $large) {
        top: rem(130);
      }

      .header--compact & {
        @media only screen and (min-width: $medium2) {
          top: $header-compact-height;
        }
      }
    }
}

.header--show-menu {

    .header__overlay {
      opacity: 0.7;
      pointer-events: auto;
    }

    &.header--compact {

      &::before {
        top: $header-compact-height;
      }
    }
}
