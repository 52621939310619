@import '../partials';
@import 'node_modules/font-awesome/scss/font-awesome';
@import 'Partials/Actions/actions';
@import 'Partials/SearchForm/search-form';
@import 'Partials/TraversableMenu/traversable-menu';
@import 'Partials/MenuPrimary/menu-primary';
@import 'Partials/MenuSecondary/menu-secondary';

.mainHeader {
  height: rem(130);
  z-index: 200;
  position: relative;

  @media only screen and (max-width: $medium2) {
    height: rem(109);
  }
}

.header {
  position: fixed;
  z-index: 100;
  width: 100%;
  color: $dark-gray;

  &__top {
    height: rem(32);
    padding: 0 rem(20);
    background-color: $blue;
    justify-content: center;
    transition: opacity 0.3s ease;
    background-color: $blue;
    background-image: url('../DocumentDefault/Assets/TRA-stripes.svg');
    background-size: auto 32px;
    background-repeat: no-repeat;
    background-position: 22%, top;

    &__boundary {
      @include content-contaner;
      justify-content: flex-end;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
    }
  }

  &__bottom {
    background-color: $white;
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    padding: 0 20px;

    &__boundary {
      @include content-contaner;
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  h1 {
    margin: 0;
  }

  &__logo {
    height: rem(30);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: rem(25) 0;
    transition: width 0.3s ease, margin-top 0.3s ease;

    @media only screen and (min-width: $medium2) {
      height: rem(45);
    }
  }

  &__social {

    &-links {
      list-style: none;
      margin: 0;
      display: flex;

      &__link {
        a {
          color: $white;
          margin: 0 0 0 rem(25);
          transition: color $fast-duration ease;

          &:hover {
            color: $orange;
          }
        }
      }
    }

    @media only screen and (max-width: $medium2) {
      display: none;
    }
  }
}
