.header {

  &__actions {
    display: flex;
    align-items: center;
  }

  &__action {
    text-align: center;
    cursor: pointer;
    display: none;

    @media only screen and (min-width: $medium2) {
      display: block;
    }

    &--is-mobile {
      display: flex;

      @media only screen and (min-width: $medium2) {
        display: none;
      }
    }

    i {
      font-size: rem(20);
    }

    &:last-child {
      i {
        padding-right: 0;
      }
    }

    &-label {
      margin-top: $desktop-vertical-padding;
      display: none;

      @media only screen and (min-width: $medium) {
        display: block;
      }
    }
  }
}
