.header__top {
  .menu {
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;

    &__items {
      display: flex;
    }

    &__link {
      font-size: rem(13);
      line-height: rem(13);
      color: $white;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      position: relative;
    }

    &__item {
      display: flex;
      align-items: center;
      padding: 20px 16px;

      &--highlighted {
        background-color: $gray;
        padding: 20px 24px;

        a {
          text-transform: none;
          font-size: rem(18);
          line-height: rem(18);
        }
      }
    }

  }
}
